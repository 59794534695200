/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */

import React from 'react';
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import { shallowEqual, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { serviceName } from '../../pbconstants';
import { OnlyLoggedIn } from '../security/OnlyLoggedIn'
import { OnlyAnonymous } from '../security/OnlyAnonymous'
//import { OnlyFreeUser } from '../security/OnlyFreeUser'
//import { OnlyBasicUser } from '../security/OnlyBasicUser'
//import { OnlyPremiumUser } from '../security/OnlyPremiumUser'
//import {OnlyBetatester} from '../security/OnlyBetatester'
import { OnlyDebuggingUI } from '../security/OnlyDebuggingUI'
import { selectUserProfileFromStoreState } from "../../state/store.oidc";
import { login, logout, register } from "../../state/session"
//import { environmentConfig } from '../../state/environmentConfigLoader'

function MenuItem({ name, target }) {
	return (<Nav.Item><Nav.Link onClick={target} >{name}</Nav.Link></Nav.Item>)
}
function DropdownMenuItem({ name, target }) {
	return (<NavDropdown.Item onClick={target}>{name}</NavDropdown.Item>);
}

export function PscoutNavigationBar({ userProfile, navigate }) {

	const routeTo = (href) => () => navigate(href)
	return (
		<Navbar expand="lg" variant="light" bg="primaryBg" className="px-4">
			<Navbar.Brand href="/#app-header">{serviceName}</Navbar.Brand>
			<Navbar.Toggle aria-controls="leftNavigationBar, rightNavigationBar" />
			<Navbar.Collapse id="leftNavigationBar">
				<Nav>
					<MenuItem name="Search" target={routeTo('/page/dataProducts')} />
					<MenuItem name="Stored Searches" target={routeTo('/page/userFilter')} />
					<MenuItem name="Bookmarks" target={routeTo('/page/bookmarked')} />
					<MenuItem name="Processing" target={routeTo('/page/processing')} />
					{/*
					<OnlyLoggedIn userProfile={userProfile}>
						<NavDropdown id="navbarDropdownShow" title="Show">
							<DropdownMenuItem name="Store regions" target={routeTo('/page/storeRegions')} />
							<DropdownMenuItem name="Stored filter" target={routeTo('/page/stored-filter')} />
							<DropdownMenuItem name="Favorite" target={routeTo('/page/favorite')} />
						</NavDropdown>
					</OnlyLoggedIn>
					<OnlyFreeUser userProfile={userProfile}>
						<NavDropdown id="navbarDropdownFreeUserFeatures" title="Free User Features">
							<DropdownMenuItem name="Free Dummy Feature A" target={routeTo('/page/dummy')} />
							<DropdownMenuItem name="Free Dummy Feature B" target={routeTo('/page/dummy')} />
						</NavDropdown>
					</OnlyFreeUser>
					<OnlyBasicUser userProfile={userProfile}>
						<NavDropdown id="navbarDropdownBasicUserFeatures" title="Basic User Features">
							<DropdownMenuItem name="Basic Dummy Feature A" target={routeTo('/page/dummy')} />
							<DropdownMenuItem name="Basic Dummy Feature B" target={routeTo('/page/dummy')} />
						</NavDropdown>
					</OnlyBasicUser>
					<OnlyPremiumUser userProfile={userProfile}>
						<NavDropdown id="navbarDropdownPremiumUserFeatures" title="Premium User Features">
							<DropdownMenuItem name="Premium Dummy Feature A" target={routeTo('/page/dummy')} />
							<DropdownMenuItem name="Premium Dummy Feature B" target={routeTo('/page/dummy')} />
						</NavDropdown>
					</OnlyPremiumUser>
					*/}
				</Nav>
			</Navbar.Collapse>
			<Navbar.Collapse id="rightNavigationBar">
				<Nav className="ms-auto">
					<MenuItem name="Documentation" target={routeTo('/page/doc')} />
					<OnlyAnonymous userProfile={userProfile}>
						<MenuItem name="Login" target={login} />
						<MenuItem name="Register" target={register} />
					</OnlyAnonymous>
					<OnlyDebuggingUI userProfile={userProfile}>
						<NavDropdown id="navbarDropdownDebug" title="Debug" >
							<DropdownMenuItem name="Test" target={routeTo('/page/test')} />
							<DropdownMenuItem name="Show access token" target={routeTo('/page/debugAccessToken')} />
						</NavDropdown>
					</OnlyDebuggingUI>
					<OnlyLoggedIn userProfile={userProfile}>
						<NavDropdown id="navbarDropdownUserName" title={userProfile.userDisplayName} >
							<DropdownMenuItem name="View Profile" target={routeTo('/page/profile/userProfile')} />
							<DropdownMenuItem name="API Token" target={routeTo('/page/profile/apiClientAccessToken')} />
							<DropdownMenuItem name="Networks" target={routeTo('/page/profile/networks')} />
							<NavDropdown.Divider />
							<DropdownMenuItem name="Logout" target={logout} />
						</NavDropdown>
					</OnlyLoggedIn>
				</Nav>
			</Navbar.Collapse>
		</Navbar>

	);
}
export function ConnectedPscoutNavigationBar() {
	const { userProfile } = useSelector(selectUserProfileFromStoreState, shallowEqual)
	const navigate = useNavigate()
	return <PscoutNavigationBar userProfile={userProfile} navigate={navigate}/>
}




/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import { environmentConfig } from '../state/environmentConfigLoader';
import {
	USER_EXPIRED,
	USER_FOUND,
	SILENT_RENEW_ERROR,
	SESSION_TERMINATED,
	LOADING_USER,
	USER_SIGNED_OUT,
	userFound,
	userExpired,
	loadUserError,
	loadingUser
} from 'redux-oidc';
import { User } from 'oidc-client';
import {createSelector} from '@reduxjs/toolkit';

//import { Log as OidcClientLog } from 'oidc-client';
//OidcClientLog.logger = console;
//OidcClientLog.level = OidcClientLog.DEBUG;

const initialState = {
	userStorageStr: null,
	refresh_token: null,
	isLoadingUser: false
};

function oidcReducer(state = initialState, action) {
	switch (action.type) {
		case USER_EXPIRED:
		case SESSION_TERMINATED:
		case USER_SIGNED_OUT:
			return initialState
		case SILENT_RENEW_ERROR:
			return {
				...state,
				userStorageStr: null,
				isLoadingUser: false
			}
		case USER_FOUND:
			return {
				...state,
				userStorageStr: action.payload.toStorageString(),
				refresh_token: action.payload.refresh_token,
				isLoadingUser: false
			}
		case LOADING_USER:
			return {
				...state,
				isLoadingUser: true
			}
		default:
			return state;
	}
}

export const ignoreUserExpiredMiddleware = (store) => (next) => (action) => {
	if(action.type === USER_EXPIRED) {
		//do nothing
	} else {
		return next(action)
	}	
}

export const oidcSlice = {
	name: 'oidc',
	reducer: oidcReducer,
	userLoadedListeners: [],
	middleware: (store) => (next) => (action) => next(action)
}

export const isLoggedIn = (user) => {
	return user == null ? false : true
}

const selectOidcUserStorageStrFromStoreState = state => state.oidc.userStorageStr

export const selectOidcUserFromStoreState = createSelector([selectOidcUserStorageStrFromStoreState], (userStorageStr) => {
  if (userStorageStr == null) {
		return null
	} else {
		return User.fromStorageString(userStorageStr)
	}
})

export const selectOidcTokensFromStoreState = (state) => {
	const user = selectOidcUserFromStoreState(state);
	if (user == null) {
		if (state.oidc.refresh_token == null) {
			return {
				access_token: null,
				refresh_token: null
			}
		} else {
			return {
				access_token: null,
				refresh_token: state.oidc.refresh_token
			}

		}
	} else {
		return {
			access_token: user.access_token,
			refresh_token: user.refresh_token
		}
	}
}

export const selectOidcAccessTokenFromStoreState = (state) => {
	const user = selectOidcUserFromStoreState(state);
	if (user == null) {
		return {
			access_token: null,
			expires_at: new Date(0)
		};
	} else {
		return {
			access_token: user.access_token,
			expires_at: user.expires_at
		};
	}
}

export const selectApiClientOidcConfigFromStoreState = (state) => {
	const user = selectOidcUserFromStoreState(state);
	if (user == null) {
		return {
			apiClientOidcConfig: null
		}
	} else {
		return {
			apiClientOidcConfig: {
				issuer: environmentConfig.oidc.authority,
				client_id: environmentConfig.oidc.apiClientId,
				user_id: user.profile.sub,
				refresh_token: user.refresh_token,
				expiration_margin_in_seconds: '60',
				api_url: environmentConfig.api.url
			}
		}
	}
}

export const anonymousUserProfile = {
	isLoggedIn: false,
	isLoadingInProgress: false,
	isFreeUser: false,
	isBasicUser: false,
	isPremiumUser: false,
	isDebuggingUI: environmentConfig.enableDebuggingUIForAnonymousUsers === true,
	isBetatester: false,
	userDisplayName: "unknown"
}

const selectOidcIsLoadingUserFromStoreState = state => state.oidc.isLoadingUser

export const selectUserProfileFromStoreState = createSelector([selectOidcIsLoadingUserFromStoreState, selectOidcUserFromStoreState], (isLoadingUser, user) => {
	if (user == null) {
		return {
			userProfile: {
				...anonymousUserProfile,
				isLoadingInProgress: isLoadingUser
			}
		}
	} else {
		return {
			userProfile: {
				isLoggedIn: true,
				isLoadingInProgress: isLoadingUser,
				isFreeUser: user.profile.roles.includes('pbsFree'),
				isBasicUser: user.profile.roles.includes('pbsBasic'),
				isPremiumUser: user.profile.roles.includes('pbsPremium'),
				isDebuggingUI: user.profile.roles.includes('debuggingUI') || environmentConfig.enableDebuggingUIForAnonymousUsers === true,
				isBetatester: environmentConfig.allAccountsAreBetatester === true || user.profile.roles.includes('pbsBeta'),
				userDisplayName: user.profile.name ? user.profile.name : user.profile.email,
				id: user.profile.sub,
				username: user.profile.preferred_username,
				fullName: user.profile.name,
				email: user.profile.email,
				emailIsVerified: user.profile.email_verified,
				familyName: user.profile.family_name,
				givenName: user.profile.given_name,
				accesScopes: user.scope.split(' '),
				roles: user.profile.roles
			}
		}
	}
})

export function loadUserFromReduxStoreToUserManager(reduxStore, userManager) {

	//console.debug("loading OIDC-user from reduxstore", reduxStore.getState().storeName)

	const userFromStore = selectOidcUserFromStoreState(reduxStore.getState())

	reduxStore.dispatch(loadingUser());

	if (userFromStore) {
		userManager.storeUser(userFromStore)
	}


	userManager.getUser()
		.then((user) => {
			if (user && !user.expired) {
				reduxStore.dispatch(userFound(user));
			} else if (!user || (user && user.expired)) {
				reduxStore.dispatch(userExpired());
			}
		})
		.catch((error) => {
			console.error(`Error in userManager.getUser(): ${error.message}`);
			reduxStore.dispatch(loadUserError());
		});
}

/**
 * Copyright (C) Petabite GmbH, 2020- - All Rights Reserved
 * Proprietary and confidential.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 */
import React from 'react'
import { connect } from 'react-redux'
import { selectUserProfileFromStoreState } from "../../state/store.oidc";

export function OnlyAnonymous({ userProfile, children }) {
	if (!userProfile.isLoggedIn) {
		return <>{children}</>
	} else {
		return null
	}
}
export const ConnectedOnlyAnonymous = connect(selectUserProfileFromStoreState)(OnlyAnonymous)

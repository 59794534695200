/*
* This is replaced by sed during build time, see .gitlab-ci.yml
*/
const _buildVersion = "production.1af67603d959160849f99c312d0db05978d79ef3";
const _buildTime = "2024-08-22T13:43:12Z";

const version = _buildVersion.includes("@@@") ? 'workstation.0000000000000000000000000000000000000000' : _buildVersion;
const regex = /(.+)\.([0-9a-f]{40})/;
const versionParts = regex.exec(version);
const loadTime = new Date();

export const buildInfo = {
	version: version,
	commitRefName: versionParts[1],
	commitHash: versionParts[2],
	buildTime: _buildTime.includes("@@@") ? loadTime.toISOString() : _buildTime
};